export enum PAYMENT_METHODS {
  PAYSTACK = "PAYSTACK",
  TRF_MOMO = "TRF_MOMO",
  TRANSFER = "TRANSFER",
  ZILLA = "ZILLA",
  FRIEND = "FRIEND",
  MONO_DIRECT_PAY = "MONO_DIRECT_PAY",
  THEPEER = "THEPEER",
  MOMO = "MOMO",
}

export interface PaymentMethodType {
  enabled: boolean;
  payment_types: string[];
  countries: string[];
  name: string;
  type: string;
  created_at: Date;
  updated_at: Date;
  id: string;
}
